export default [
  {
    path: '/master',
    name: 'master',
    component: () => import(/* webpackChunkName: "master" */ '@/view/master/index.vue'),
    children: [
      {
        path: 'satuan-kpi',
        name: 'master-satuan-kpi',
        component: () => import(/* webpackChunkName: "satuan-kpi" */ '@/view/master/satuan-kpi/index.vue'),
      },
      {
        path: 'satuan-kpi-add',
        name: 'master-satuan-kpi-add',
        component: () => import(/* webpackChunkName: "satuan-kpi-add" */ '@/view/master/satuan-kpi/add.vue'),
      },
      {
        path: 'satuan-kpi-edit/:id',
        name: 'master-satuan-kpi-edit',
        component: () => import(/* webpackChunkName: "satuan-kpi-edit" */ '@/view/master/satuan-kpi/edit.vue'),
      },
      {
        path: 'jenis-variable-kpi',
        name: 'master-jenis-variable-kpi',
        component: () =>
          import(/* webpackChunkName: "jenis-variable-kpi" */ '@/view/master/jenis-variable-kpi/index.vue'),
      },
      {
        path: 'jenis-variable-kpi-add',
        name: 'master-jenis-variable-kpi-add',
        component: () =>
          import(/* webpackChunkName: "jenis-variable-kpi-add" */ '@/view/master/jenis-variable-kpi/add.vue'),
      },
      {
        path: 'jenis-variable-kpi-edit/:id',
        name: 'master-jenis-variable-kpi-edit',
        component: () =>
          import(/* webpackChunkName: "jenis-variable-kpi-edit" */ '@/view/master/jenis-variable-kpi/edit.vue'),
      },
      {
        path: 'kpi-korporat',
        name: 'master-kpi-korporat',
        component: () => import(/* webpackChunkName: "kpi-korporat" */ '@/view/master/kpi-korporat/index.vue'),
      },
      {
        path: 'kpi-korporat-add',
        name: 'master-kpi-korporat-add',
        component: () => import(/* webpackChunkName: "kpi-korporat-add" */ '@/view/master/kpi-korporat/add.vue'),
      },
      {
        path: 'kpi-korporat-edit/:id',
        name: 'master-kpi-korporat-edit',
        component: () => import(/* webpackChunkName: "kpi-korporat-edit" */ '@/view/master/kpi-korporat/edit.vue'),
      },
      {
        path: 'variable-kpi-korporat',
        name: 'master-variable-kpi-korporat',
        component: () =>
          import(/* webpackChunkName: "variable-kpi-korporat" */ '@/view/master/variable-kpi-korporat/index.vue'),
      },
      {
        path: 'variable-kpi-korporat-add',
        name: 'master-variable-kpi-korporat-add',
        component: () =>
          import(/* webpackChunkName: "variable-kpi-korporat-add" */ '@/view/master/variable-kpi-korporat/add.vue'),
      },
      {
        path: 'variable-kpi-korporat-edit/:id',
        name: 'master-variable-kpi-korporat-edit',
        component: () =>
          import(/* webpackChunkName: "variable-kpi-korporat-edit" */ '@/view/master/variable-kpi-korporat/edit.vue'),
      },
      {
        path: 'realisasi-variable-kpi-korporat',
        name: 'master-realisasi-variable-kpi-korporat',
        component: () =>
          import(
            /* webpackChunkName: "realisasi-variable-kpi-korporat" */ '@/view/master/realisasi-variable-kpi-korporat/index.vue'
          ),
      },
      {
        path: 'realisasi-variable-kpi-korporat-add',
        name: 'master-realisasi-variable-kpi-korporat-add',
        component: () =>
          import(
            /* webpackChunkName: "realisasi-variable-kpi-korporat-add" */ '@/view/master/realisasi-variable-kpi-korporat/add.vue'
          ),
      },
      {
        path: 'realisasi-variable-kpi-korporat-edit/:id',
        name: 'master-realisasi-variable-kpi-korporat-edit',
        component: () =>
          import(
            /* webpackChunkName: "realisasi-variable-kpi-korporat-edit" */ '@/view/master/realisasi-variable-kpi-korporat/edit.vue'
          ),
      },
      {
        path: 'realisasi-kpi-korporat',
        name: 'master-realisasi-kpi-korporat',
        component: () =>
          import(/* webpackChunkName: "realisasi-kpi-korporat" */ '@/view/master/realisasi-kpi-korporat/index.vue'),
      },
      {
        path: 'realisasi-kpi-korporat-add',
        name: 'master-realisasi-kpi-korporat-add',
        component: () =>
          import(/* webpackChunkName: "realisasi-kpi-korporat-add" */ '@/view/master/realisasi-kpi-korporat/add.vue'),
      },
      {
        path: 'realisasi-kpi-korporat-edit/:id',
        name: 'master-realisasi-kpi-korporat-edit',
        component: () =>
          import(/* webpackChunkName: "realisasi-kpi-korporat-edit" */ '@/view/master/realisasi-kpi-korporat/edit.vue'),
      },
      {
        path: 'target-kpi-korporat',
        name: 'master-target-kpi-korporat',
        component: () =>
          import(/* webpackChunkName: "target-kpi-korporat" */ '@/view/master/target-kpi-korporat/index.vue'),
      },
      {
        path: 'target-kpi-korporat-add',
        name: 'master-target-kpi-korporat-add',
        component: () =>
          import(/* webpackChunkName: "target-kpi-korporat-add" */ '@/view/master/target-kpi-korporat/add.vue'),
      },
      {
        path: 'target-kpi-korporat-edit/:id',
        name: 'master-target-kpi-korporat-edit',
        component: () =>
          import(/* webpackChunkName: "target-kpi-korporat-edit" */ '@/view/master/target-kpi-korporat/edit.vue'),
      },
      {
        path: 'laporan-manajemen',
        name: 'master-laporan-manajemen',
        component: () =>
          import(/* webpackChunkName: "laporan-manajemen" */ '@/view/master/laporan-manajemen/index.vue'),
      },
      {
        path: 'laporan-manajemen-add',
        name: 'master-laporan-manajemen-add',
        component: () =>
          import(/* webpackChunkName: "laporan-manajemen-add" */ '@/view/master/laporan-manajemen/add.vue'),
      },
      {
        path: 'laporan-manajemen-edit/:id',
        name: 'master-laporan-manajemen-edit',
        component: () =>
          import(/* webpackChunkName: "laporan-manajemen-edit" */ '@/view/master/laporan-manajemen/edit.vue'),
      },
      {
        path: 'jenis-variable-laporan-manajemen',
        name: 'master-jenis-variable-laporan-manajemen',
        component: () =>
          import(
            /* webpackChunkName: "jenis-variable-laporan-manajemen" */ '@/view/master/jenis-variable-laporan-manajemen/index.vue'
          ),
      },
      {
        path: 'jenis-variable-laporan-manajemen-add',
        name: 'master-jenis-variable-laporan-manajemen-add',
        component: () =>
          import(
            /* webpackChunkName: "jenis-variable-laporan-manajemen-add" */ '@/view/master/jenis-variable-laporan-manajemen/add.vue'
          ),
      },
      {
        path: 'jenis-variable-laporan-manajemen-edit/:id',
        name: 'master-jenis-variable-laporan-manajemen-edit',
        component: () =>
          import(
            /* webpackChunkName: "jenis-variable-laporan-manajemen-edit" */ '@/view/master/jenis-variable-laporan-manajemen/edit.vue'
          ),
      },
      {
        path: 'variable-laporan-manajemen',
        name: 'master-variable-laporan-manajemen',
        component: () =>
          import(
            /* webpackChunkName: "variable-laporan-manajemen" */ '@/view/master/variable-laporan-manajemen/index.vue'
          ),
      },
      {
        path: 'variable-laporan-manajemen-add',
        name: 'master-variable-laporan-manajemen-add',
        component: () =>
          import(
            /* webpackChunkName: "variable-laporan-manajemen-add" */ '@/view/master/variable-laporan-manajemen/add.vue'
          ),
      },
      {
        path: 'variable-laporan-manajemen-edit/:id',
        name: 'master-variable-laporan-manajemen-edit',
        component: () =>
          import(
            /* webpackChunkName: "variable-laporan-manajemen-edit" */ '@/view/master/variable-laporan-manajemen/edit.vue'
          ),
      },
      {
        path: 'divisi',
        name: 'master-divisi',
        component: () => import(/* webpackChunkName: "divisi" */ '@/view/master/divisi/index.vue'),
      },
      {
        path: 'divisi-add',
        name: 'master-divisi-add',
        component: () => import(/* webpackChunkName: "divisi-add" */ '@/view/master/divisi/add.vue'),
      },
      {
        path: 'divisi-edit/:id',
        name: 'master-divisi-edit',
        component: () => import(/* webpackChunkName: "divisi-edit" */ '@/view/master/divisi/edit.vue'),
      },
      {
        path: 'direktorat',
        name: 'master-direktorat',
        component: () => import(/* webpackChunkName: "direktorat" */ '@/view/master/direktorat/index.vue'),
      },
      {
        path: 'direktorat-add',
        name: 'master-direktorat-add',
        component: () => import(/* webpackChunkName: "direktorat-add" */ '@/view/master/direktorat/add.vue'),
      },
      {
        path: 'direktorat-edit/:id',
        name: 'master-direktorat-edit',
        component: () => import(/* webpackChunkName: "direktorat-edit" */ '@/view/master/direktorat/edit.vue'),
      },
      {
        path: 'kpi-divisi',
        name: 'master-kpi-divisi',
        component: () => import(/* webpackChunkName: "kpi-divisi" */ '@/view/master/kpi-divisi/index.vue'),
      },
      {
        path: 'kpi-divisi-add',
        name: 'master-kpi-divisi-add',
        component: () => import(/* webpackChunkName: "kpi-divisi-add" */ '@/view/master/kpi-divisi/add.vue'),
      },
      {
        path: 'kpi-divisi-edit/:id',
        name: 'master-kpi-divisi-edit',
        component: () => import(/* webpackChunkName: "kpi-divisi-edit" */ '@/view/master/kpi-divisi/edit.vue'),
      },
      {
        path: 'variable-kpi-divisi',
        name: 'master-variable-kpi-divisi',
        component: () =>
          import(/* webpackChunkName: "variable-kpi-divisi" */ '@/view/master/variable-kpi-divisi/index.vue'),
      },
      {
        path: 'variable-kpi-divisi-add',
        name: 'master-variable-kpi-divisi-add',
        component: () =>
          import(/* webpackChunkName: "variable-kpi-divisi-add" */ '@/view/master/variable-kpi-divisi/add.vue'),
      },
      {
        path: 'variable-kpi-divisi-edit/:id',
        name: 'master-variable-kpi-divisi-edit',
        component: () =>
          import(/* webpackChunkName: "variable-kpi-divisi-edit" */ '@/view/master/variable-kpi-divisi/edit.vue'),
      },
      {
        path: 'realisasi-variable-kpi-divisi',
        name: 'master-realisasi-variable-kpi-divisi',
        component: () =>
          import(
            /* webpackChunkName: "realisasi-variable-kpi-divisi" */ '@/view/master/realisasi-variable-kpi-divisi/index.vue'
          ),
      },
      {
        path: 'realisasi-variable-kpi-divisi-add',
        name: 'master-realisasi-variable-kpi-divisi-add',
        component: () =>
          import(
            /* webpackChunkName: "realisasi-variable-kpi-divisi-add" */ '@/view/master/realisasi-variable-kpi-divisi/add.vue'
          ),
      },
      {
        path: 'realisasi-variable-kpi-divisi-edit/:id',
        name: 'master-realisasi-variable-kpi-divisi-edit',
        component: () =>
          import(
            /* webpackChunkName: "realisasi-variable-kpi-divisi-edit" */ '@/view/master/realisasi-variable-kpi-divisi/edit.vue'
          ),
      },
      {
        path: 'realisasi-kpi-divisi',
        name: 'master-realisasi-kpi-divisi',
        component: () =>
          import(/* webpackChunkName: "realisasi-kpi-divisi" */ '@/view/master/realisasi-kpi-divisi/index.vue'),
      },
      {
        path: 'realisasi-kpi-divisi-add',
        name: 'master-realisasi-kpi-divisi-add',
        component: () =>
          import(/* webpackChunkName: "realisasi-kpi-divisi-add" */ '@/view/master/realisasi-kpi-divisi/add.vue'),
      },
      {
        path: 'realisasi-kpi-divisi-edit/:id',
        name: 'master-realisasi-kpi-divisi-edit',
        component: () =>
          import(/* webpackChunkName: "realisasi-kpi-divisi-edit" */ '@/view/master/realisasi-kpi-divisi/edit.vue'),
      },
      {
        path: 'target-kpi-divisi',
        name: 'master-target-kpi-divisi',
        component: () =>
          import(/* webpackChunkName: "target-kpi-divisi" */ '@/view/master/target-kpi-divisi/index.vue'),
      },
      {
        path: 'target-kpi-divisi-add',
        name: 'master-target-kpi-divisi-add',
        component: () =>
          import(/* webpackChunkName: "target-kpi-divisi-add" */ '@/view/master/target-kpi-divisi/add.vue'),
      },
      {
        path: 'target-kpi-divisi-edit/:id',
        name: 'master-target-kpi-divisi-edit',
        component: () =>
          import(/* webpackChunkName: "target-kpi-divisi-edit" */ '@/view/master/target-kpi-divisi/edit.vue'),
      },
      {
        path: 'perusahaan',
        name: 'master-perusahaan',
        component: () => import(/* webpackChunkName: "perusahaan" */ '@/view/master/perusahaan/index.vue'),
      },
      {
        path: 'perusahaan-add',
        name: 'master-perusahaan-add',
        component: () => import(/* webpackChunkName: "perusahaan-add" */ '@/view/master/perusahaan/add.vue'),
      },
      {
        path: 'perusahaan-edit/:id',
        name: 'master-perusahaan-edit',
        component: () => import(/* webpackChunkName: "perusahaan-edit" */ '@/view/master/perusahaan/edit.vue'),
      },
      {
        path: 'bidang-usaha',
        name: 'master-bidang-usaha',
        component: () => import(/* webpackChunkName: "bidang-usaha" */ '@/view/master/bidang-usaha/index.vue'),
      },
      {
        path: 'bidang-usaha-add',
        name: 'master-bidang-usaha-add',
        component: () => import(/* webpackChunkName: "bidang-usaha-add" */ '@/view/master/bidang-usaha/add.vue'),
      },
      {
        path: 'bidang-usaha-edit/:id',
        name: 'master-bidang-usaha-edit',
        component: () => import(/* webpackChunkName: "bidang-usaha-edit" */ '@/view/master/bidang-usaha/edit.vue'),
      },
      {
        path: 'mitra-pemasok',
        name: 'master-mitra-pemasok',
        component: () => import(/* webpackChunkName: "mitra-pemasok" */ '@/view/master/mitra-pemasok/index.vue'),
      },
      {
        path: 'mitra-pemasok-add',
        name: 'master-mitra-pemasok-add',
        component: () => import(/* webpackChunkName: "mitra-pemasok-add" */ '@/view/master/mitra-pemasok/add.vue'),
      },
      {
        path: 'mitra-pemasok-edit/:id',
        name: 'master-mitra-pemasok-edit',
        component: () => import(/* webpackChunkName: "mitra-pemasok-edit" */ '@/view/master/mitra-pemasok/edit.vue'),
      },
      {
        path: 'pltu',
        name: 'master-pltu',
        component: () => import(/* webpackChunkName: "pltu" */ '@/view/master/pltu/index.vue'),
      },
      {
        path: 'pltu-add',
        name: 'master-pltu-add',
        component: () => import(/* webpackChunkName: "pltu-add" */ '@/view/master/pltu/add.vue'),
      },
      {
        path: 'pltu-edit/:id',
        name: 'master-pltu-edit',
        component: () => import(/* webpackChunkName: "pltu-edit" */ '@/view/master/pltu/edit.vue'),
      },
      {
        path: 'user',
        name: 'master-user',
        component: () => import(/* webpackChunkName: "user" */ '@/view/master/user/index.vue'),
      },
      {
        path: 'user-add',
        name: 'master-user-add',
        component: () => import(/* webpackChunkName: "user-add" */ '@/view/master/user/add.vue'),
      },
      {
        path: 'user-edit/:id',
        name: 'master-user-edit',
        component: () => import(/* webpackChunkName: "user-edit" */ '@/view/master/user/edit.vue'),
      },
      {
        path: 'role',
        name: 'master-role',
        component: () => import(/* webpackChunkName: "role" */ '@/view/master/role/index.vue'),
      },
      {
        path: 'role-add',
        name: 'master-role-add',
        component: () => import(/* webpackChunkName: "role-add" */ '@/view/master/role/add.vue'),
      },
      {
        path: 'role-edit/:id',
        name: 'master-role-edit',
        component: () => import(/* webpackChunkName: "role-edit" */ '@/view/master/role/edit.vue'),
      },
      {
        path: 'permission',
        name: 'master-permission',
        component: () => import(/* webpackChunkName: "permission" */ '@/view/master/permission/index.vue'),
      },
      {
        path: 'permission-add',
        name: 'master-permission-add',
        component: () => import(/* webpackChunkName: "permission-add" */ '@/view/master/permission/add.vue'),
      },
      {
        path: 'permission-edit/:id',
        name: 'master-permission-edit',
        component: () => import(/* webpackChunkName: "permission-edit" */ '@/view/master/permission/edit.vue'),
      },
      {
        path: 'pegawai',
        name: 'master-pegawai',
        component: () => import(/* webpackChunkName: "pegawai" */ '@/view/master/pegawai/index.vue'),
      },
      {
        path: 'pegawai-add',
        name: 'master-pegawai-add',
        component: () => import(/* webpackChunkName: "pegawai-add" */ '@/view/master/pegawai/add.vue'),
      },
      {
        path: 'pegawai-edit/:id',
        name: 'master-pegawai-edit',
        component: () => import(/* webpackChunkName: "pegawai-edit" */ '@/view/master/pegawai/edit.vue'),
      },
      {
        path: 'pegawai-import',
        name: 'master-pegawai-import',
        component: () => import(/* webpackChunkName: "pegawai-import" */ '@/view/master/pegawai/import.vue'),
      },
      {
        path: 'vendor',
        name: 'master-vendor',
        component: () => import(/* webpackChunkName: "vendor" */ '@/view/master/vendor/index.vue'),
      },
      {
        path: 'vendor-add',
        name: 'master-vendor-add',
        component: () => import(/* webpackChunkName: "vendor-add" */ '@/view/master/vendor/add.vue'),
      },
      {
        path: 'vendor-edit/:id',
        name: 'master-vendor-edit',
        component: () => import(/* webpackChunkName: "vendor-edit" */ '@/view/master/vendor/edit.vue'),
      },
      {
        path: 'vendor-import',
        name: 'master-vendor-import',
        component: () => import(/* webpackChunkName: "vendor-import" */ '@/view/master/vendor/import.vue'),
      },
      {
        path: 'divisi-bidang',
        name: 'master-divisi-bidang',
        component: () => import(/* webpackChunkName: "divisi-bidang" */ '@/view/master/divisi-bidang/index.vue'),
      },
      {
        path: 'divisi-bidang-add',
        name: 'master-divisi-bidang-add',
        component: () => import(/* webpackChunkName: "divisi-bidang-add" */ '@/view/master/divisi-bidang/add.vue'),
      },
      {
        path: 'divisi-bidang-edit/:id',
        name: 'master-divisi-bidang-edit',
        component: () => import(/* webpackChunkName: "divisi-bidang-edit" */ '@/view/master/divisi-bidang/edit.vue'),
      },
      {
        path: 'bidang',
        name: 'master-bidang',
        component: () => import(/* webpackChunkName: "bidang" */ '@/view/master/bidang/index.vue'),
      },
      {
        path: 'bidang-add',
        name: 'master-bidang-add',
        component: () => import(/* webpackChunkName: "bidang-add" */ '@/view/master/bidang/add.vue'),
      },
      {
        path: 'bidang-edit/:id',
        name: 'master-bidang-edit',
        component: () => import(/* webpackChunkName: "bidang-edit" */ '@/view/master/bidang/edit.vue'),
      },
    ],
  },
];
