import mutations from './mutations';
import { DataService } from '@/config/dataService/dataService';
import { notification } from 'ant-design-vue';

const addNotificationSuccess = message => {
  notification.success({
    message: message,
  });
};

const addNotificationError = err => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = message => {
  notification.success({
    message: message,
  });
};

const deleteNotificationError = err => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = message => {
  notification.success({
    message: message,
  });
};

const updateNotificationError = err => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
  detail: [],
});

const actions = {
  async axiosCrudSubmitData({ commit }, { url, data }) {
    try {
      await commit('axiosAddBeginBegin');
      const response = await DataService.post(`/${url}`, data);
      if (response.status === 200) {
        commit('axiosAddBeginSuccess', response.data.data);
        addNotificationSuccess(response.data.message);
      } else {
        commit('axiosAddBeginErr', response.data.message);
        addNotificationError(response.data.message);
      }
      // fix me later
      // if (response.data.status === 200) {
      //   await commit('axiosAddBeginSuccess', response.data.data);
      //   addNotificationSuccess(response.data.message);
      // } else {
      //   await commit('axiosAddBeginErr', response.data.message);
      //   addNotificationError(response.data.message);
      // }
    } catch (err) {
      await commit('axiosAddBeginErr', err);
      console.log(err);
      addNotificationError(err.message);
    }
  },

  async axiosCrudSubmitData2({ commit }, { id, url, data }) {
    try {
      await commit('axiosAddBeginBegin');
      const response = await DataService.post(`/${url}/${id}?_method=PUT`, data);
      await commit('axiosAddBeginSuccess', response.data.data);
      addNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosAddBeginErr', err);
      console.log(err);
      addNotificationError(err.message);
    }
  },

  async axiosCrudSubmitDataRedirect({ commit }, { url, data, redirect }) {
    try {
      await commit('axiosAddBeginBegin');
      const response = await DataService.post(`/${url}`, data);
      await commit('axiosAddBeginSuccessRedirect', { data: response.data.data, redirect });
      addNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosAddBeginErr', err);
      console.log(err);
      addNotificationError(err.message);
    }
  },

  async axiosCrudSubmitDataNoRedirect({ commit }, { url, data }) {
    try {
      await commit('axiosAddBeginBegin');
      const response = await DataService.post(`/${url}`, data);
      commit('axiosAddBeginSuccessNoRedirect', response.data.data);
      addNotificationSuccess(response.data.message);
      return response.data;
    } catch (err) {
      await commit('axiosAddBeginErr', err);
      console.log(err);
      addNotificationError(err.message);
    }
  },

  async axiosCrudGetData({ commit }, data) {
    try {
      await commit('axiosReadBegin');
      const query = await DataService.get(`/${data}`);
      await commit('axiosReadSuccess', query.data);
    } catch (err) {
      console.log(err);
      await commit('axiosReadErr', err);
    }
  },

  async axiosCrudGetDataDetail({ commit }, data) {
    try {
      await commit('axiosReadBegin');
      const query = await DataService.get(`/${data}`);
      await commit('axiosReadDetailSuccess', query.data);
    } catch (err) {
      console.log(err);
      await commit('axiosReadErr', err);
    }
  },

  async axiosDataSearch({ commit }, { url, filter }) {
    try {
      await commit('axiosReadBegin');
      if (filter != '') {
        const query = await DataService.get(`/${url}?filter=${filter}`);
        await commit('axiosReadSuccess', query.data);
      } else {
        try {
          const query = await DataService.get(`/${url}`);
          await commit('axiosReadSuccess', query.data);
        } catch (err) {
          console.log(err);
          await commit('axiosReadErr', err);
        }
      }
    } catch (err) {
      console.log(err);
      await commit('axiosReadErr', err);
    }
  },

  async axiosFileClear({ commit }) {
    try {
      await commit('axiosUploadBegin');
      commit('axiosUploadSuccess', null);
    } catch (err) {
      await commit('axiosUploadErr', err);
    }
  },

  async axiosFileUploader({ commit }, imageAsFile) {
    let data = new FormData();
    data.set('image', imageAsFile);
    try {
      await commit('axiosUploadBegin');
      const query = await DataService.post('/image-upload', data, { 'Content-Type': 'multipart/form-data' });
      console.log(query.data);
      commit('axiosUploadSuccess', `img/basics/${query.data}`);
    } catch (err) {
      await commit('axiosUploadErr', err);
    }
  },

  async axiosSingleDataGet({ commit }, data) {
    try {
      await commit('axiosSingleDataBegin');
      const query = await DataService.get(`/${data.url}/${data.id}`);
      commit('axiosSingleDataSuccess', query.data.data);
      // commit('axiosUploadSuccess', query.data.data.image);
    } catch (err) {
      await commit('axiosSingleDataErr', err);
    }
  },

  async axiosDataDelete({ commit }, { id, url, getData }) {
    try {
      await commit('axiosDeleteBegin');
      const response = await DataService.delete(`/${url}/${id}`);
      await commit('axiosDeleteSuccess');
      await getData();
      deleteNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosDeleteErr', err);
      console.log(err);
      deleteNotificationError(err);
    }
  },

  async axiosDataResetPassword({ commit }, { id, getData }) {
    try {
      await commit('axiosResetPasswordBegin');
      const response = await DataService.post(`/user/reset-password/${id}`);
      await commit('axiosResetPasswordSuccess');
      await getData();
      deleteNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosResetPasswordErr', err);
      console.log(err);
      deleteNotificationError(err);
    }
  },

  async axiosDataUnlockUser({ commit }, { id, getData }) {
    try {
      await commit('axiosUnlockUserBegin');
      const response = await DataService.post(`/user/unlock-user/${id}`);
      await commit('axiosUnlockUserSuccess');
      await getData();
      deleteNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosUnlockUserErr', err);
      console.log(err);
      deleteNotificationError(err);
    }
  },

  async axiosDataApprove({ commit }, { id, url, getData }) {
    try {
      await commit('axiosApproveBegin');
      const response = await DataService.post(`/${url}/${id}`);
      await commit('axiosApproveSuccess');
      await getData();
      deleteNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosApproveErr', err);
      console.log(err);
      deleteNotificationError(err);
    }
  },

  async axiosDataUpdate({ commit }, { id, url, data }) {
    try {
      await commit('axiosUpdateBegin');
      const response = await DataService.put(`/${url}/${id}`, data);
      if (response.status === 200) {
        commit('axiosUpdateSuccess', response.data.data);
        addNotificationSuccess(response.data.message);
      } else {
        commit('axiosUpdateErr', response.data.message);
        addNotificationError(response.data.message);
      }
    } catch (err) {
      await commit('axiosUpdateErr', err);
      updateNotificationError(err);
    }
  },

  async axiosDataUpdateRedirect({ commit }, { id, url, data, redirect }) {
    try {
      await commit('axiosUpdateBegin');
      const response = await DataService.put(`/${url}/${id}`, data);
      commit('axiosUpdateSuccessRedirect', redirect);

      updateNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosUpdateErr', err);
      updateNotificationError(err);
    }
  },

  async axiosDataUpload({ commit }, { url, data, redirect }) {
    try {
      await commit('axiosUploadBegin');
      const response = await DataService.post(`/${url}`, data);
      commit('axiosUploadSuccess', redirect);

      updateNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosUploadErr', err);
      updateNotificationError(err);
    }
  },

  async axiosDataDeleteFile({ commit }, { id, url, getData }) {
    try {
      await commit('axiosDeleteBegin');
      const response = await DataService.delete(`/${url}/${id}`);
      await commit('axiosDeleteSuccess');
      await getData();
      deleteNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosDeleteErr', err);
      console.log(err);
      deleteNotificationError(err);
    }
  },

  async axiosDownloadTemplate({ commit }, { url, fileName }) {
    try {
      await commit('axiosDownloadTemplateBegin');
      const response = await DataService.getFile(`/${url}`);

      // Membuat URL blob untuk file yang diunduh
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName; // Nama file untuk disimpan
      link.click();
      await commit('axiosDownloadTemplateSuccess');
    } catch (err) {
      console.error(err);
      await commit('axiosDownloadTemplateErr', err);
      notification.error({
        message: 'Failed to download template',
        description: err.message,
      });
    }
  },

  async axiosSearchNik({ commit }, { search }) {
    try {
      await commit('axiosSearchNikBegin');
      const query = await DataService.post(`/check-nik`, { search });
      await commit('axiosSearchNikSuccess', query.data);
    } catch (err) {
      console.log(err);
      await commit('axiosSearchNikErr', err);
    }
  },

  async agreePrivacyPolicy({ commit }) {
    try {
      await commit('axiosAgreeBegin');
      const response = await DataService.post(`/accept-policy`);
      commit('axiosAgreeSuccess', response.data.data);
      addNotificationSuccess(response.data.message);
    } catch (err) {
      await commit('axiosAgreeErr', err);
      updateNotificationError(err);
    }
  },

  async checkPrivacyPolicy({ commit }) {
    try {
      await commit('axiosReadBegin');
      const response = await DataService.get(`/check-user-privacy-agreement`);
      await commit('axiosReadSuccess', response.data);
    } catch (err) {
      console.log(err);
      await commit('axiosReadErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
