// import PageRoutes from './PageRoutes';
import KpiRoutes from './KpiRoutes';
import LaporanManajemenRoutes from './LaporanManajemenRoutes';
import MasterRoutes from './MasterRoutes';
import DashRoutes from './DashRoutes';
import InternalRoutes from './InternalRoutes';
import EksternalRoutes from './EksternalRoutes';
import NotificationRoutes from './NotificationRoutes';
import AntiCoiRoutes from './AntiCoiRoutes';

const routes = [
  ...KpiRoutes,
  ...LaporanManajemenRoutes,
  ...MasterRoutes,
  ...DashRoutes,
  ...InternalRoutes,
  ...EksternalRoutes,
  ...NotificationRoutes,
  ...AntiCoiRoutes,
];

export default routes;
